import './main-stage.scss';
// import $ from 'jquery';

class MainStage {
    constructor ($element) {
        this.$mainStage = $element;
        this.$itemsToMove = this.$mainStage.querySelectorAll('[data-main-stage="move"]');
        this.type = this.$mainStage.getAttribute('data-main-stage-type');
        this.$mobileStatic = this.$mainStage.querySelector('[data-main-stage-static-mobile]');
        this.$staticImage = null;
    }

    initialize () {
        if (this.type === 'animation') {
            if (!this.$mobileStatic) {
                const isTouchDevice = 'ontouchstart' in document.documentElement;
                const windowWidth = window.innerWidth;

                if (!isTouchDevice && windowWidth > 1023) {
                    if (this.$itemsToMove.length > 0) {
                        this.$mainStage.addEventListener('mousemove', (e) => {
                            this.stageAnimation(e);
                        });
                    }
                }

                window.resizeHandler.customFunctions.push(() => {
                    const newWindowWidth = window.innerWidth;

                    if (newWindowWidth < 1024) {
                        this.$mainStage.addEventListener('mousemove', (e) => {
                            for (let i = 0; i < this.$itemsToMove.length; i++) {
                                const $itemToMove = this.$itemsToMove[i];
                                this.setMobileStage($itemToMove);
                            }
                        });
                    } else {
                        this.$mainStage.addEventListener('mousemove', (e) => {
                            this.stageAnimation(e);
                        });
                    }
                });
            } else {
                window.resizeHandler.customFunctions.push(() => {
                    if (window.innerWidth >= 768) {
                        window.location.reload();
                    }
                });
            }
        }
        // if (this.type === 'static') {
        //     this.$staticImage = this.$mainStage.querySelector('[data-main-stage-static-image]');
        //
        //     if (this.$staticImage.classList.contains('has--ripples')) {
        //         import('jquery.ripples').then(() => {
        //             $('.main-stage__static-image').ripples({
        //                 perturbance: 0.01
        //             });
        //         });
        //     }
        // }
    }

    stageAnimation (e) {
        for (let i = 0; i < this.$itemsToMove.length; i++) {
            const $itemToMove = this.$itemsToMove[i];
            const type = $itemToMove.getAttribute('data-main-stage-type');
            this.setTransitions(e, $itemToMove, type);
        }
    }

    setTransitions (e, elem, type) {
        const _w = window.innerWidth / 2;
        const _h = window.innerHeight / 2;
        const _mouseX = e.clientX;
        const _mouseY = e.clientY;
        let _depthMultiplier = 0.01;

        switch (type) {
        case '1':
            _depthMultiplier = 0.04;
            break;
        case '2':
            _depthMultiplier = 0.05;
            break;
        case '3':
            _depthMultiplier = 0.045;
            break;
        case '4':
            _depthMultiplier = 0.045;
            break;
        }

        const _depth = `${50 - (_mouseX - _w) * _depthMultiplier}% ${50 - (_mouseY - _h) * _depthMultiplier}%`;
        const x = `${_depth}`;
        elem.style.backgroundPosition = x;
    }

    setMobileStage (elem) {
        elem.style.backgroundPosition = '50% 50%';
    }
}

export { MainStage };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $mainStages = $context.querySelectorAll('[data-main-stage="root"]');
        if ($mainStages.length > 0) {
            $mainStages.forEach((mainStage) => {
                const $mainStage = new MainStage(mainStage);
                $mainStage.initialize();
            });
        }
    }
});
